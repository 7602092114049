import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CualidadesUsuario } from "../modelos/cualidades_usuario.model";
import { CualidadesService } from "../cualidades.service";
import { ExperienciaService } from "../experiencia.service";

import { Observable } from "rxjs";

@Component({
  selector: "app-pop-up-listado",
  templateUrl: "./pop-up-listado.component.html",
  styleUrls: ["./pop-up-listado.component.css"]
})
export class PopUpListadoComponent implements OnInit {
  formularioCualidades;
  @Input("titulo") titulo: string;
  @Input("editar") editar: boolean;
  @Input("objeto") cualidadesParametros: CualidadesUsuario;
  @Output() propagar = new EventEmitter<boolean>();
  @Output() cualidades = new EventEmitter<CualidadesUsuario>();
  //atributoParametros = new Atributos();

  opciones: any[];
  anio: number = new Date().getFullYear();

  onSubmit() {
    switch(this.titulo){
    case "Formación":
    this.experienciaService.addFormacioUsuario(this.cualidadesParametros).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    break;
    case "Herramientas digitales":
    this.experienciaService.addHerramientaUsuario(this.cualidadesParametros).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    break;
    case "Cursos":
    this.experienciaService.addCursoUsuario(this.cualidadesParametros).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    break;
    case "Idiomas":
    this.experienciaService.addIdiomaUsuario(this.cualidadesParametros).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    break;
      case "Editar Formación":
        this.experienciaService.editFormacioUsuario(this.cualidadesParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Editar Curso":
        this.experienciaService.editCursoUsuario(this.cualidadesParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Editar Herramienta":
        this.experienciaService.editHerramientaUsuario(this.cualidadesParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Editar Idioma":
        this.experienciaService.editIdiomaUsuario(this.cualidadesParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
    }
  }

  error(error) {
    alert("Error desconocido");
    console.error(error);
  }
  buscar(id){
    this.opciones;    
        var cont = 0;
        var idEncontrada;
        this.opciones.forEach(function(value) {
          if (value.id == id) {
            idEncontrada = cont;
          }
          cont++;
        });
        return this.opciones[idEncontrada].Nombre;
  }
  respuesta(data) {
    if (data.status == "200") {
      console.log(data);
      this.cualidadesParametros.id = data.success;
      this.cualidadesParametros.Cualidad = this.buscar(this.cualidadesParametros.idCualidad);
      this.cualidades.emit(this.cualidadesParametros);
    } else {
      alert("Error");
      console.log(data);
    }
    //this.propagar.emit(false);
  }

  faArrowLeft = faArrowLeft;
  constructor(protected cualidadesService: CualidadesService, protected experienciaService: ExperienciaService) {}

  ngOnInit() {
    //console.log(this.objeto);
    switch (this.titulo) {
      case "Formación":
      case "Editar Formación":
        this.cualidadesService.getTitulacion().subscribe({
          next: data => (this.opciones = <any[]>data), //this.estado = data.status,
          error: error => this.error(error) //this.estado = error
        });
      this.formularioCualidades = new FormGroup({
        Inicio: new FormControl("", [Validators.required]),
        Lugar: new FormControl("", Validators.required),
        IdCualidad: new FormControl("", Validators.required),
        Final: new FormControl("", [Validators.required])
      });
        break;
      case "Cursos":
      case "Editar Curso":
        this.cualidadesService.getCursos().subscribe({
          next: data => (this.opciones = <any[]>data), //this.estado = data.status,
          error: error => this.error(error) //this.estado = error
        });
      this.formularioCualidades = new FormGroup({
        Inicio: new FormControl("", [Validators.required, Validators.max(this.anio)]),
        Lugar: new FormControl("", Validators.required),
        IdCualidad: new FormControl("", Validators.required),
        Final: new FormControl("", [Validators.required, Validators.max(this.anio)])
      });
        break;
      case "Idiomas":
      case "Editar Idioma":
        this.cualidadesService.getIdiomas().subscribe({
          next: data => (this.opciones = <any[]>data), //this.estado = data.status,
          error: error => this.error(error) //this.estado = error
        });
      this.formularioCualidades = new FormGroup({
        Lugar: new FormControl("", Validators.required),
        IdCualidad: new FormControl("", Validators.required),
        Nivel: new FormControl("", Validators.required)
      });
        break;
      case "Herramientas digitales":
      case "Editar Herramienta":
        this.cualidadesService.getHerramienta().subscribe({
          next: data => (this.opciones = <any[]>data), //this.estado = data.status,
          error: error => this.error(error) //this.estado = error
        });
      this.formularioCualidades = new FormGroup({
        IdCualidad: new FormControl("", Validators.required),
        Nivel: new FormControl("", Validators.required)
      });
        break;
    }

    if (!this.editar) {
      this.cualidadesParametros = new CualidadesUsuario();
    }
   
  }
/*
   private loadPeople() {
        this.peopleLoading = true;
        this.dataService.getPeople().subscribe(x => {
            this.people = x;
            this.peopleLoading = false;
        });
    }*/


  cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }

}
