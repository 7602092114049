import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-select-predictivo",
  templateUrl: "./select-predictivo.component.html",
  styleUrls: ["./select-predictivo.component.css"]
})
export class SelectPredictivoComponent implements OnInit {
  opciones: any[];

  @Input("placeholder") placeholder:string;
  @Input()
  set setOpciones(opciones: any[]) {
    this.opciones = opciones;//(opciones && opciones.trim()) || '<no name set>';
    for (var i = 0; i < this.opciones.length; i++) {
      var campo = this.opciones[i];
      campo["activo"] = true;
      campo["seleccionado"] = false;

      this.opciones[i] = campo;
    }
  }


  mostrar = false;
  constructor() {}
  cambio(event) {
    var buscar = "";
    if(event.key.length == 1){
      buscar = event.target.value + event.key;
    }else{
      if(event.key == "Backspace"){
      buscar = event.target.value.slice(0, -1);
    }else{
      buscar = event.target.value;
    }
    }
    for (var i = 0; i < this.opciones.length; i++) {
      if (
        this.opciones[i]["Nombre"].toLowerCase().indexOf(buscar.toLowerCase()) != -1
      ) {
        this.opciones[i]["activo"] = true;
      } else {
        this.opciones[i]["activo"] = false;
      }
    }
  }
  contenido_input = "";
  seleccionar(opcion) {
    opcion["seleccionado"] = true;
    this.contenido_input="";
    for (var i = 0; i < this.opciones.length; i++) {
        this.opciones[i]["activo"] = true;
    }
    this.mostrar = false;
  }
  eliminar(opcion) {
    opcion["seleccionado"] = false;
  }
  ngOnInit() {
   
   
  }
  Mostrar(){
    this.mostrar = !this.mostrar;
  }
}
