import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Validar } from "../modelos/validar.model";
import { UsersService } from "../users.service";

import { Observable } from "rxjs";

@Component({
  selector: "app-validar",
  templateUrl: "./validar.component.html",
  styleUrls: ["./validar.component.css"]
})
export class ValidarComponent implements OnInit {
  ngOnInit() {
    let id: number = +this.route.snapshot.queryParamMap.get("id");
    this.usuarioParametros.id = id;
    this.usuarioParametros.key = this.route.snapshot.queryParamMap.get("key");

    this.userService.validarUsuario(this.usuarioParametros).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  error(error) {
    alert("Error desconocido");

    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      this.router.navigate([""]);
    } else {
      alert("Error desconocido");
      console.log(data);
    }
  }
  usuarioParametros = new Validar();

  constructor(
    protected userService: UsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}
