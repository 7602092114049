import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { CheckForUpdateService } from './check-for-update.service';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent  {
  updateAvailable = false;
  
  constructor(private updates: SwUpdate,
    private checkForUpdateService: CheckForUpdateService) {
      this.updates.available.subscribe((event) => {
        this.updateAvailable = true;
      });
  }
  
}
