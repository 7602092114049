import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Atributos } from "../app/modelos/atributos.model";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { CualidadesUsuario } from "./modelos/cualidades_usuario.model";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})
export class ExperienciaService { 
  private url = environment.url;
  constructor(protected http: HttpClient) {
    console.log("Servicio atributo creación");
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token") !== null) {
      console.log("Entra token");
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }

  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    })
  };

  ponerToken() {
    this.httpOptions.headers = this.httpOptions.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    console.log("Entra poner token");
  }

  addExperiencia(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.post<Atributos>(
      this.url + "/api/experience",
      atributo,
      this.httpOptions
    );
  }
  editExperiencia(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.put<Atributos>(
      this.url + "/api/experience/" + atributo.id,
      atributo,
      this.httpOptions
    );
  }
  removeExperiencia(id: number): Observable<Atributos> {
    this.ponerToken();
    return this.http.delete<Atributos>(this.url + "/api/experience/" + id, this.httpOptions);
  }

  //voluntariado
  addVoluntariado(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.post<Atributos>(
      this.url + "/api/voluntariado",
      atributo,
      this.httpOptions
    );
  }
  editVoluntariado(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.put<Atributos>(
      this.url + "/api/voluntariado/" + atributo.id,
      atributo,
      this.httpOptions
    );
  }
  removeVoluntariado(id: number): Observable<Atributos> {
    this.ponerToken();
    return this.http.delete<Atributos>(
      this.url + "/api/voluntariado/" + id,
      this.httpOptions
    );
  }

  //publicaciones
  addPublicacion(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.post<Atributos>(
      this.url + "/api/publicacion",
      atributo,
      this.httpOptions
    );
  }
  editPublicacion(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.put<Atributos>(
      this.url + "/api/publicacion/" + atributo.id,
      atributo,
      this.httpOptions
    );
  }
  removePublicacion(id: number): Observable<Atributos> {
    this.ponerToken();
    return this.http.delete<Atributos>(
      this.url + "/api/publicacion/" + id,
      this.httpOptions
    );
  }

  //atribuciones
  addAtribucion(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.post<Atributos>(
      this.url + "/api/atribucion",
      atributo,
      this.httpOptions
    );
  }
  editAtribucion(atributo: Atributos): Observable<Atributos> {
    this.ponerToken();
    return this.http.put<Atributos>(
      this.url + "/api/atribucion/" + atributo.id,
      atributo,
      this.httpOptions
    );
  }
  removeAtribucion(id: number): Observable<Atributos> {
    this.ponerToken();
    return this.http.delete<Atributos>(
      this.url + "/api/atribucion/" + id,
      this.httpOptions
    );
  }

  //formación
  addFormacioUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.post<CualidadesUsuario>(
      this.url + "/api/formacion_usuario",
      cualidades,
      this.httpOptions
    );
  }
  removeFormacioUsuario(id: number): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.delete<CualidadesUsuario>(
      this.url + "/api/formacion_usuario/" + id,
      this.httpOptions
    );
  }
  editFormacioUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.put<CualidadesUsuario>(
      this.url + "/api/formacion_usuario/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }

  //curso
  addCursoUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.post<CualidadesUsuario>(
      this.url + "/api/curso_usuario",
      cualidades,
      this.httpOptions
    );
  }
  removeCursoUsuario(id: number): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.delete<CualidadesUsuario>(
      this.url + "/api/curso_usuario/" + id,
      this.httpOptions
    );
  }
  editCursoUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.put<CualidadesUsuario>(
      this.url + "/api/curso_usuario/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }

  //idioma
  addIdiomaUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.post<CualidadesUsuario>(
      this.url + "/api/idioma_usuario",
      cualidades,
      this.httpOptions
    );
  }
  removeIdiomaUsuario(id: number): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.delete<CualidadesUsuario>(
      this.url + "/api/idioma_usuario/" + id,
      this.httpOptions
    );
  }
  editIdiomaUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.put<CualidadesUsuario>(
      this.url + "/api/idioma_usuario/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }

  //herramienta
  addHerramientaUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.post<CualidadesUsuario>(
      this.url + "/api/herramienta_usuario",
      cualidades,
      this.httpOptions
    );
  }
  removeHerramientaUsuario(id: number): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.delete<CualidadesUsuario>(
      this.url + "/api/herramienta_usuario/" + id,
      this.httpOptions
    );
  }
  editHerramientaUsuario(
    cualidades: CualidadesUsuario
  ): Observable<CualidadesUsuario> {
    this.ponerToken();
    return this.http.put<CualidadesUsuario>(
      this.url + "/api/herramienta_usuario/" + cualidades.id,
      cualidades,
      this.httpOptions
    );
  }
}
