import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ReunionService } from '../reunion.service';
import { Reunion } from '../modelos/reunion.model';


@Component({
  selector: 'app-reunion-form',
  templateUrl: './reunion-form.component.html',
  styleUrls: ['./reunion-form.component.css']
})
export class ReunionFormComponent implements OnInit {

  faArrowLeft=faArrowLeft;
  idProceso;
  reunion:any = new Reunion();
  
@Input('candidato') candidato: number;
@Input('proceso') proceso: number;
@Output() propagar = new EventEmitter<boolean>();
formularioProceso= new FormGroup({
  Fecha : new FormControl('',Validators.required),
  Hora : new FormControl('',Validators.required),
  Lugar : new FormControl('',Validators.required)
});
  constructor(    protected reunionService: ReunionService) { }

 cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }
  ngOnInit() {
    this.reunion.Candidato = this.candidato;
    this.reunion.Proceso = this.proceso;
  }

 onSubmit(){
  this.reunionService.postReunion(this.reunion).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
}
error(error) {   
      alert("Error desconocido");
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      console.log(data);
    this.propagar.emit(true);
    } else {
      alert("Error");
    console.log(data);
    }
    //this.propagar.emit(false);
  }

}