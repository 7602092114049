import { Component, OnInit } from "@angular/core";
import { faArrowLeft, faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { ReglasService } from "../reglas.service";
import { NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-puntuacion",
  templateUrl: "./puntuacion.component.html",
  styleUrls: ["./puntuacion.component.css"]
})
export class PuntuacionComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faTrash = faTrash;
  popUp = false;
  faPen = faPen;
  reglas: any;
  paginaReglas: any;
  activoConfirm = false;
  previousPage;
  pageSize = 10;
  mensaje = "";
  idActual = "";
  showPagination;
  totalItems;
  reglaEditar:any = {};
  editarPop = false;
  page = 1;
  constructor(protected reglasService: ReglasService) {}

  ngOnInit() {
    this.reglasService.verReglas().subscribe(
      data => {
        this.reglas = data;
        this.totalItems = this.reglas.length;
        if (this.totalItems > this.pageSize) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.previousPage = 1;
        this.fijarPagina(1);
      },
      error => {
        console.error(error);
      }
    );
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.fijarPagina(this.page - 1);
    }
  }
  fijarPagina(page: number): void {
    this.paginaReglas = this.reglas.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }
  abrirPopUp() {
    this.editarPop = false;
    this.popUp = true;
  }
  cerrarPopUp(valor: boolean) {
    this.popUp = false;
  }
  recibidaRegla(val) {
    var obj: any = {};
    obj = val;
    if(this.editarPop){
      this.editarPop = false;

      for(var i = 0; i < this.reglas.length; i++){
        if(this.reglas[i].id == val.id){
          this.reglas[i].Puntos = obj.puntos;
          break;
        }
      }

    }else{
    var regla: any = {
      id: obj.id,
      Tipo: obj.tipo,
      Categoria: obj.categoria,
      Puntos: obj.puntos,
      Condicion: obj.condicion,
      Nombre: obj.nombre
    };

    regla.nombre = "";
    this.reglas.push(regla);
    this.totalItems = this.reglas.length;
     if (this.totalItems > this.pageSize) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
    
  }
  this.popUp = false;
    this.fijarPagina(this.previousPage);
  }
  
  editar(res) {
    this.editarPop = true;

    this.reglaEditar.id = res.id;
    this.reglaEditar.puntos = res.Puntos;
    this.reglaEditar.nombre = res.Nombre;
    console.log(this.reglaEditar);
    this.popUp = true;
  }
  eliminar(id,nombre){
    this.idActual = id;
    this.mensaje = "Seguro que desea eliminar la regla "+ nombre;
this.activoConfirm = true;
  }
  procesaCerrarConfirmar(res){
if(res){
  //borrar regla
  this.reglasService.eliminarReglas(this.idActual).subscribe(
    data => {
      console.log(data);
      this.activoConfirm = false;

      for (var i = 0; i < this.reglas.length; i++) {
      if (this.reglas[i].id == this.idActual) {
        this.reglas.splice(i, 1);
        break;
      }
    }
    this.totalItems = this.reglas.length;
     if (this.totalItems > this.pageSize) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
    this.fijarPagina(this.previousPage);

      this.idActual = "";


      },
    error => console.error(error)
  );
}else{
this.activoConfirm = false;
      this.idActual = "";
}
  }
}
