import { Component, OnInit } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Registro } from "../modelos/registro.model";
import { UsersService } from "../users.service";
import { Router } from "@angular/router";

import { Observable } from "rxjs";

@Component({
  selector: "app-registro",
  templateUrl: "./registro.component.html",
  styleUrls: ["./registro.component.css"]
})
export class RegistroComponent implements OnInit {
  anio: number = new Date().getFullYear();
  faArrowLeft = faArrowLeft;

  formularioRegistro = new FormGroup({
    nombre: new FormControl("", Validators.required),
    username: new FormControl("", [Validators.required, Validators.email]),
    tel: new FormControl("", Validators.required),
    ciudad: new FormControl("", Validators.required),
    ciudad2: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    pass2: new FormControl("", Validators.required)
  });

  constructor(protected userService: UsersService, private router: Router) {}
  usuarioParametros = new Registro();
  ngOnInit() {}
  onSubmit() {
    this.userService.addUsuario(this.usuarioParametros).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
  error(error) {
    if (error.status == "409") {
      alert("mail repetido");
    } else {
      alert("Error desconocido");
    }
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      this.ventana = true;
    } else {
    }
    console.log(data);
  }
  ventana = false;
  cerrarVentana(){
    this.router.navigate([""]);
  }
}
