import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { Global } from './global';

import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from './app.component';
import { HelloComponent } from './hello.component';
import { UsersService } from './users.service';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AutenticarComponent } from './autenticar/autenticar.component';
import { RegistroComponent } from './registro/registro.component';
import { PerfilComponent } from './perfil/perfil.component';


import { ImageCropperModule } from 'ngx-image-cropper';

import { ToasterComponent } from './toaster/toaster.component';
import { CheckForUpdateService } from './check-for-update.service';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { MisCentrosComponent } from './mis-centros/mis-centros.component';
import { PopUpComponent } from './pop-up/pop-up.component';
import { ExperienciaService } from './experiencia.service';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { PerfilSuperUsuarioComponent } from './perfil-super-usuario/perfil-super-usuario.component';
import { PerfilDirectorComponent } from './perfil-director/perfil-director.component';
import { CentroService } from './centro.service';
import { LineaCentroComponent } from './linea-centro/linea-centro.component';
import { NuevoCentroComponent } from './nuevo-centro/nuevo-centro.component';
import { ValidarComponent } from './validar/validar.component';
import { EditarPerfilComponent } from './editar-perfil/editar-perfil.component';
import { PerfilCandidatoComponent } from './perfil-candidato/perfil-candidato.component';
import { CualidadesService } from './cualidades.service';
import { PopUpCualidadesComponent } from './pop-up-cualidades/pop-up-cualidades.component';
import { ListadoCualidadesComponent } from './listado-cualidades/listado-cualidades.component';
import { PopUpListadoComponent } from './pop-up-listado/pop-up-listado.component';
import { PopUpCentrosComponent } from './pop-up-centros/pop-up-centros.component';
import { BuscarCandidatoComponent } from './buscar-candidato/buscar-candidato.component';
import { LineaUsuarioComponent } from './linea-usuario/linea-usuario.component';
import { UserComponent } from './user/user.component';
import { SelectPredictivoComponent } from './select-predictivo/select-predictivo.component';
import { SeleccionService } from './seleccion.service';
import { ProcesoComponent } from './proceso/proceso.component';
import { NewProcesoComponent } from './new-proceso/new-proceso.component';
import { PopUpAddProcesoComponent } from './pop-up-add-proceso/pop-up-add-proceso.component';
import { TablaProcesoSeleccionComponent } from './tabla-proceso-seleccion/tabla-proceso-seleccion.component';
import { ComentariosComponent } from './comentarios/comentarios.component';
import { ComentariosService } from './comentarios.service';
import { ReunionService } from './reunion.service';
import { ReunionFormComponent } from './reunion-form/reunion-form.component';
import { ModalImageComponent } from './modal-image/modal-image.component';
import { PuntuacionComponent } from './puntuacion/puntuacion.component';
import { ReglasService } from './reglas.service';
import { PopUpReglaComponent } from './pop-up-regla/pop-up-regla.component';
import { ProcesosPublicosComponent } from './procesos-publicos/procesos-publicos.component';
import { ModalImageCentroComponent } from './modal-image-centro/modal-image-centro.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  imports:      [ BrowserModule, HttpClientModule, FormsModule, ReactiveFormsModule,NgbModule,NgSelectModule,ImageCropperModule,
    FontAwesomeModule,
    RouterModule.forRoot([
    { path: '', component: AutenticarComponent },
    { path: 'registro', component: RegistroComponent },
    { path: 'users', component: UsuariosComponent },
    { path: 'perfil', component: PerfilComponent },
    { path: 'notificaciones', component: NotificacionesComponent },
    { path: 'mis_centros', component: MisCentrosComponent },
    { path: 'new_centros', component: NuevoCentroComponent },
    { path: 'validar', component: ValidarComponent },
    { path: 'editar_perfil', component: EditarPerfilComponent },
    { path: 'buscar_candidato', component: BuscarCandidatoComponent },
    { path: 'user/:id', component: UserComponent },
    { path: 'centro/:id', component: NuevoCentroComponent },
    { path: 'proceso/:id', component: ProcesoComponent },
    { path: 'proceso', component: ProcesoComponent },
    { path: 'newProceso', component: NewProcesoComponent },
    { path: 'editProceso/:id', component: NewProcesoComponent },
    { path: 'coment', component: ComentariosComponent },
    { path: 'puntuacion', component: PuntuacionComponent },
    { path: 'procesos', component: ProcesosPublicosComponent },
], { relativeLinkResolution: 'legacy' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }) ],
  declarations: [ AppComponent, HelloComponent, UsuariosComponent, AutenticarComponent, RegistroComponent, PerfilComponent,ToasterComponent, TopBarComponent, NotificacionesComponent, MisCentrosComponent, PopUpComponent, ConfirmacionComponent, PerfilSuperUsuarioComponent, PerfilDirectorComponent, LineaCentroComponent, NuevoCentroComponent, ValidarComponent, EditarPerfilComponent, PerfilCandidatoComponent, PopUpCualidadesComponent, ListadoCualidadesComponent, PopUpListadoComponent, PopUpCentrosComponent, BuscarCandidatoComponent, LineaUsuarioComponent, UserComponent, SelectPredictivoComponent, ProcesoComponent, NewProcesoComponent, PopUpAddProcesoComponent, TablaProcesoSeleccionComponent, ComentariosComponent, ReunionFormComponent, ModalImageComponent, PuntuacionComponent, PopUpReglaComponent, ProcesosPublicosComponent, ModalImageCentroComponent ],
  bootstrap:    [ AppComponent ],
  providers: [UsersService, ExperienciaService, CentroService, CualidadesService, SeleccionService, ComentariosService, ReunionService,Global, ReglasService,CheckForUpdateService]
  
})
export class AppModule { }
