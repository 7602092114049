import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Centro } from "../app/modelos/centro.model";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { Seleccion } from "./modelos/seleccion.model";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})

export class SeleccionService { 
  private url = environment.url;
  constructor(protected http: HttpClient) {
    console.log("Servicio atributo creación");
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token") !== null) {
      console.log("Entra token");
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    }),
    body:null
  };
  ponerToken(){
    this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      console.log("Entra poner token");
  }

  
  addCandidato(proceso: number, user: number): Observable<Centro> {
    this.ponerToken();
    var datos:any = {};
    var vector:number[] = [];
    vector.push(user);
    datos.Candidatos = vector;
  return this.http.post<Centro>(this.url+'/api/seleccion/candidatos/'+proceso, datos, this.httpOptions);
}

addMeCandidato(proceso: number): Observable<Centro> {
  this.ponerToken();
  var vector:number[] = [];
return this.http.post<Centro>(this.url+'/api/seleccion/me/candidatos/'+proceso, null, this.httpOptions);
}
  contratar(proceso: number, user: number): Observable<Centro> {
    var datos:any = {};
    datos.Candidato = user;
  return this.http.post<Centro>(this.url+'/api/seleccion/contrato/'+proceso, datos, this.httpOptions);
}
  setProceso(atributo: Seleccion): Observable<Centro> {
    this.ponerToken();
  return this.http.post<Centro>(this.url+'/api/seleccion', atributo, this.httpOptions);
}

  getProcesos() {
    this.ponerToken();
    return this.http.get(this.url + "/api/seleccion", this.httpOptions);
}

  getProcesosLista() {
    this.ponerToken();
    return this.http.get(this.url + "/api/seleccionList", this.httpOptions);
}
getProcesosListaPerfil() {
    this.ponerToken();
    return this.http.get(this.url + "/api/seleccionListPerfil", this.httpOptions);
}


  getProceso(proceso: number) {
    this.ponerToken();
    return this.http.get(this.url + "/api/seleccion/"+proceso, this.httpOptions);
}

 eliminarCandidato(proceso: number, user: number): Observable<Centro> {
    this.ponerToken();
    var datos:any = {};
    var vector:number[] = [];
    vector.push(user);
    datos.Candidatos = vector;
    this.httpOptions.body = datos;
  return this.http.delete<Centro>(this.url+'/api/seleccion/candidatos/'+proceso, this.httpOptions);
}
 eliminarProceso(idSeleccion: number): Observable<Centro> {
    this.ponerToken();
  return this.http.delete<Centro>(this.url+'/api/seleccion/'+idSeleccion, this.httpOptions);
}
   editarProceso(seleccion: Seleccion): Observable<Seleccion> {
    return this.http.put<Seleccion>(this.url + "/api/seleccion/"+seleccion.id, seleccion, this.httpOptions);
  }

}
