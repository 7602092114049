import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Centro } from "../app/modelos/centro.model";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/internal/operators";
import { environment } from '../environments/environment';

@Injectable({
  providedIn: "root"
})

export class CentroService {
  private url = environment.url;
  constructor(protected http: HttpClient) {
    console.log("Servicio atributo creación");
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token") !== null) {
      console.log("Entra token");
      this.httpOptions.headers = this.httpOptions.headers.set(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
    }
  }
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "my-auth-token"
    })
  };
  ponerToken() {
    this.httpOptions.headers = this.httpOptions.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    console.log("Entra poner token");
  }


  addCentro(atributo: Centro): Observable<Centro> {
    this.ponerToken();
    return this.http.post<Centro>(this.url + '/api/centro', atributo, this.httpOptions);
  }
  editCentro(atributo: Centro): Observable<Centro> {
    this.ponerToken();
    return this.http.put<Centro>(this.url + '/api/centro', atributo, this.httpOptions);
  }

  getCentros() {
    this.ponerToken();
    return this.http.get(this.url + "/api/centro", this.httpOptions);
  }
  getMisCentros() {
    this.ponerToken();
    return this.http.get(this.url + "/api/misCentros", this.httpOptions);
  }

  getCentro($id) {
    this.ponerToken();
    return this.http.get(this.url + "/api/centro/" + $id, this.httpOptions);
  }

  addCentroUsuario(atributo: Centro): Observable<Centro> {
    this.ponerToken();
    return this.http.post<Centro>(this.url + '/api/centros', atributo, this.httpOptions);
  }
  removeCentroUsuario(atributo: Centro): Observable<Centro> {
    this.ponerToken();
    return this.http.delete<Centro>(this.url + '/api/centros/' + atributo.id, this.httpOptions);
  }
  removeCentro(id): Observable<Centro> {
    this.ponerToken();
    return this.http.delete<Centro>(this.url + '/api/centro/' + id, this.httpOptions);
  }

}
