import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Registro } from "../modelos/registro.model";
import { Reunion } from "../modelos/reunion.model";
import { faCalendarAlt, faCheck, faTimes, faQuestion,faGraduationCap, faBriefcase} from "@fortawesome/free-solid-svg-icons";
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";

@Component({
  selector: 'app-linea-usuario',
  templateUrl: './linea-usuario.component.html',
  styleUrls: ['./linea-usuario.component.css']
})
export class LineaUsuarioComponent implements OnInit {
  @Input("usuario") usuario: Registro;
  @Input("proceso") proceso: boolean;
  @Input("reunion") reunion: Reunion;
  urlServer = environment.url;
@Output() reunionBtn = new EventEmitter<number>();
@Output() click = new EventEmitter<number>();
@Output() contrato = new EventEmitter<number>();
faCalendar = faCalendarAlt;
faCheck = faCheck;
faBriefcase = faBriefcase;
faGraduationCap = faGraduationCap;
faTimes = faTimes;
faQuestion = faQuestion;

  constructor(private router: Router) {}

  ngOnInit() {
    
  }
newLink(user_id){
  const url = this.router.serializeUrl(
    this.router.createUrlTree([`/user/${user_id}`])
  );

  window.open(url, '_blank');
}
  AddAProceso(event: Event){
    this.click.emit(this.usuario.id);
  }
   EliminarProceso(event: Event){
    this.click.emit(this.usuario.id);
  }

  
   ContratarProceso(event: Event){
    this.contrato.emit(this.usuario.id);
  }
   PedirReunion(event: Event){
    this.reunionBtn.emit(this.usuario.id);
  }

}