import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Atributos } from "../modelos/atributos.model";
import { ExperienciaService } from "../experiencia.service";

import { Observable } from "rxjs";

@Component({
  selector: "app-pop-up",
  templateUrl: "./pop-up.component.html",
  styleUrls: ["./pop-up.component.css"]
})
export class PopUpComponent implements OnInit {
  formularioAtributo;
  @Input("titulo") titulo: string;
  @Input("editar") editar: boolean;
  @Input("objeto") atributoParametros: Atributos;
  @Output() propagar = new EventEmitter<boolean>();
  @Output() atributo = new EventEmitter<Atributos>();
  //atributoParametros = new Atributos();

  anio: number = new Date().getFullYear();
  onSubmit() {
    switch (this.titulo) {
      case "Experiencia laboral":
        this.experienceService
          .addExperiencia(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Voluntariado":
        this.experienceService
          .addVoluntariado(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
        case "Publicaciones":
        this.experienceService
          .addPublicacion(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Habilitaciones":
        this.experienceService
          .addAtribucion(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Editar Habilitación":
        this.experienceService
          .editAtribucion(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Editar Experiencia":
        this.experienceService
          .editExperiencia(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
      case "Editar Voluntariado":
        this.experienceService
          .editVoluntariado(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
        case "Editar Publicación":
        this.experienceService
          .editPublicacion(this.atributoParametros)
          .subscribe({
            next: data => this.respuesta(data), //this.estado = data.status,
            error: error => this.error(error) //this.estado = error
          });
        break;
    }
  }

  error(error) {
    alert("Error desconocido");
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      console.log(data);
      this.atributoParametros.id = data.success;
      this.atributo.emit(this.atributoParametros);
    } else {
      alert("Error");
      console.log(data);
    }
    //this.propagar.emit(false);
  }

  faArrowLeft = faArrowLeft;
  constructor(protected experienceService: ExperienciaService) {}

  ngOnInit() {
    console.log(this.objeto);

    if (
      this.titulo == "Experiencia laboral" ||
      this.titulo == "Editar Experiencia"
    ) {
      this.formularioAtributo = new FormGroup({
        puesto: new FormControl("", Validators.required),
        centro: new FormControl("", Validators.required),
        inicio: new FormControl("", [Validators.required, Validators.max(this.anio)]),
        fin: new FormControl("", [Validators.required, Validators.max(this.anio)]),
        descripcion: new FormControl("", Validators.required)
      });
    } else {
      if (
        "Habilitaciones" == this.titulo ||
        "Editar Habilitación" == this.titulo
      ) {
        this.formularioAtributo = new FormGroup({
          puesto: new FormControl("", Validators.required)
        });
      } else {
        if(this.titulo == "Publicaciones" ||this.titulo == "Editar Publicación"){
        this.formularioAtributo = new FormGroup({
          titulo: new FormControl("", Validators.required),
          autor: new FormControl("", Validators.required),
          medio: new FormControl(""),
          revista: new FormControl(""),
          volumen: new FormControl(""),
          url: new FormControl(""),
          fecha: new FormControl("", Validators.required)
        });

        }else{
        this.formularioAtributo = new FormGroup({
          centro: new FormControl("", Validators.required),
          inicio: new FormControl("", [Validators.required, Validators.max(this.anio)]),
          fin: new FormControl("", Validators.max(this.anio)),
          descripcion: new FormControl("", Validators.required)
        });
        }
      }
    }

    if (!this.editar) {
      this.atributoParametros = new Atributos();
    }
    // this.activo = false;
  }
  objeto(objeto: any) {
    throw new Error("Method not implemented.");
  }
  cerrarPopUp(event: Event) {
    this.propagar.emit(false);
  }
}
