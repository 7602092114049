import { Component, OnInit } from '@angular/core';
import { Seleccion } from '../modelos/seleccion.model';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { SeleccionService } from '../seleccion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-new-proceso',
  templateUrl: './new-proceso.component.html',
  styleUrls: ['./new-proceso.component.css']
})
export class NewProcesoComponent implements OnInit {
faArrowLeft=faArrowLeft;


formularioSeleccion;

  onSubmit() {
    if(this.newProceso){
    this.seleccionService.setProceso(this.proceso).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    }else{
    this.seleccionService.editarProceso(this.proceso).subscribe({
      next: data => this.respuesta(data), //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
    }
  }
newProceso:boolean = true;
  constructor(protected seleccionService: SeleccionService, private router: Router, private route: ActivatedRoute) { }
proceso = new Seleccion;
usuario;
  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem("user"));
      this.newProceso = false;
    if (this.route.snapshot.params['id']) {
    this.route.paramMap.subscribe(params => {
      let id_buscar:number = + params.get("id");
      this.seleccionService.getProceso(id_buscar).subscribe(data => {
        console.log(data);
        this.proceso = <Seleccion>data;
        var separado = this.proceso.Inicio.split('-');
        this.proceso.Inicio = separado[2]+'-'+separado[1]+'-'+separado[0];
        
        var separadoF = this.proceso.Fin.split('-');
        this.proceso.Fin = separadoF[2]+'-'+separadoF[1]+'-'+separadoF[0];
      });
    });
    this.formularioSeleccion = new FormGroup({
    Titulo: new FormControl("", Validators.required),
    Nivel: new FormControl("", Validators.required),
    Descripcion: new FormControl(""),    
    Inicio: new FormControl("", Validators.required),
    Fin: new FormControl("", Validators.required),
    Horas: new FormControl("", Validators.required),
    Tipo: new FormControl("", Validators.required)
  });
    }else{
      this.newProceso = true;
      this.formularioSeleccion = new FormGroup({
    Titulo: new FormControl("", Validators.required),
    Nivel: new FormControl("", Validators.required),
    Descripcion: new FormControl(""),    
    Inicio: new FormControl("", Validators.required),
    Fin: new FormControl("", Validators.required),
    Horas: new FormControl("", Validators.required),
    Tipo: new FormControl("", Validators.required),
    Centro: new FormControl("", Validators.required)
  });
    }
  }

  error(error) {
    alert("Error desconocido");
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      console.log(data);
      this.router.navigate(["/perfil"]);
    } else {
      alert("Error");
      console.log(data);
    }
    //this.propagar.emit(false);
  }
}