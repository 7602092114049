import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { Router } from "@angular/router";
import { Registro } from "../modelos/registro.model";
import { CentroService } from "../centro.service";
import { Centro } from "../modelos/centro.model";
import { environment } from '../../environments/environment';

@Component({
  selector: "app-mis-centros",
  templateUrl: "./mis-centros.component.html",
  styleUrls: ["./mis-centros.component.css"]
})
export class MisCentrosComponent implements OnInit {
  constructor(
    protected userService: UsersService,
    protected centroService: CentroService,
    private router: Router) {}
  procesaCerrarPopUp(mensaje) {
    this.popUp = mensaje;
    this.centroService.getMisCentros().subscribe(
      data => {
        this.misCentros =  <Centro[]>data;
      },
      error => {
        console.error(error);
        alert("Error");
      }
    );
    for (var i = 0; i < this.misCentros.length; i++) {
      this.misCentros[i].Seleccionado = undefined;
    }
  }
  abrirPopUp() {
    this.popUp = true;
  }
  centros: Centro[];
  urlServer = environment.url;
  misCentros: Centro[] = [];
  superUser: boolean;
  directorUser: boolean;
  normalUser: boolean;
  popUp: boolean;
  usuario = new Registro();
  ngOnInit() {
    if (localStorage.getItem("user") !== null) {
      this.usuario = JSON.parse(localStorage.getItem("user"));


      this.centroService.getMisCentros().subscribe(
        data => {
          this.misCentros =  <Centro[]>data;
          for (var i = 0; i < this.misCentros.length; i++) {
            this.misCentros[i].Seleccionado = undefined;
          }
        },
        error => {
          console.error(error);
          alert("Error");
        }
      );


      
      this.centroService.getCentros().subscribe(
        data => {
          this.centros = <Centro[]>data;
          var cent = new Centro();
          cent.Nombre = "Pedro";
          //this.misCentros = data;
          this.evaluar();
        },
        error => {
          console.error(error);
          alert("Error");
        }
      );
    } else {
      this.router.navigate([""]);
    }
  }
  evaluar() {
    for (var i = 0; i < this.centros.length; i++) {
      for (var j = 0; j < this.misCentros.length; j++) {
        if (this.centros[i].id == this.misCentros[j].id) {
          this.centros[i].Seleccionado = true;
          break;
        }
      }
      if (this.centros[i].Seleccionado == undefined) {
        console.log("entra");
        this.centros[i].Seleccionado = false;
      }
    }
  }
}
