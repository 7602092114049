import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ReunionService } from "../reunion.service";
import { environment } from '../../environments/environment';

@Component({
  selector: "app-notificaciones",
  templateUrl: "./notificaciones.component.html",
  styleUrls: ["./notificaciones.component.css"]
})
export class NotificacionesComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  urlServer = environment.url;
  notificaciones: any[];
  filtrado: any[];
  filtrado_mes: any[] = new Array();
  filtrado_anyo: any[] = new Array();
  filtrado_semana: any[] = new Array();
  filtro: string;
  usuario = JSON.parse(localStorage.getItem("user"));
  constructor(protected userService: UsersService,protected reunionService: ReunionService) {}

  ngOnInit() {
    this.filtro = "Ver todo";
    this.userService.getNotificaciones().subscribe(
      data => {
        var d = new Date();
        var d_sem = new Date();
        d_sem.setDate(d.getDate() - 7);
        this.notificaciones = <any[]>data;
        this.filtrado = <any[]>data;
        for (var i = 0; i < this.notificaciones.length; i++) {
          var st = this.notificaciones[i]["Fecha"];
          var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
          var dt = new Date(st.replace(pattern, "$3-$2-$1"));
          console.log(dt);
          if (d_sem.getTime() < dt.getTime()) {
            this.filtrado_semana.push(this.notificaciones[i]);
            this.filtrado_mes.push(this.notificaciones[i]);
            this.filtrado_anyo.push(this.notificaciones[i]);
          } else {
            if (dt.getMonth() == d.getMonth()) {
              this.filtrado_mes.push(this.notificaciones[i]);
              this.filtrado_anyo.push(this.notificaciones[i]);
            } else {
              if (dt.getFullYear() == d.getFullYear()) {
                this.filtrado_anyo.push(this.notificaciones[i]);
              }
            }
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }
  aceptarReunion(id:number, id_not:number){
    this.reunionService.aceptarReunion(id).subscribe(
      data => {
        console.log(data);
        for(var i = 0; i < this.filtrado.length; i++){
          if(this.filtrado[i].id == id_not){
            this.filtrado[i].Estado = 1;
          }
        }
      },error=>{
        console.log(error);
      });
  }
  rechazarReunion(id:number, id_not:number){
    this.reunionService.rechazarReunion(id).subscribe(
      data => {
        console.log(data);
        for(var i = 0; i < this.filtrado.length; i++){
          if(this.filtrado[i].id == id_not){
            this.filtrado[i].Estado = 2;
          }
        }
      },error=>{
        console.log(error);
      });
  }
  cambioFiltro(new_filtro: string) {
    this.filtro = new_filtro;
    switch (this.filtro) {
      case "Ver todo":
        this.filtrado = this.notificaciones;
        break;
      case "Última semana":
        this.filtrado = this.filtrado_semana;
        break;
      case "Este mes":
        this.filtrado = this.filtrado_mes;

        break;
      case "Este año":
        this.filtrado = this.filtrado_anyo;

        break;
    }
  }
  accion(data) {
    alert(data);
  }
}
