import { Component, OnInit, Input } from "@angular/core";
import { Comentario } from "../modelos/comentario.model";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ComentariosService } from "../comentarios.service";
import { faPaperPlane, faTrash, faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import { NgbPaginationConfig } from "@ng-bootstrap/ng-bootstrap";
import { environment } from '../../environments/environment';


@Component({
  selector: "app-comentarios",
  templateUrl: "./comentarios.component.html",
  styleUrls: ["./comentarios.component.css"]
})
export class ComentariosComponent implements OnInit {
  id_user;
  url = environment.url;
  @Input("desplegado") desplegado: boolean;
  @Input("candidato") candidato: number;
  @Input("comentarios") comentarios: Comentario[];
  paginaComentarios: Comentario[];
  abierto: boolean;
  miId: number;
  clase: string;
  faPaperPlane = faPaperPlane;
  previousPage;
  faTrash = faTrash;
  flecha = faAngleDown;
  /*paginacion*/
  pageSize = 2;
  showPagination;
  totalItems;
  page = 1;
  constructor(protected comentarioService: ComentariosService
  ) {}
  onSubmit() { 
    this.comentario.candidato = this.candidato.toString();
    console.log(this.comentario);
    this.comentarioService.addComentario(this.comentario).subscribe({
      next: data => {
        this.comentario.id = data.success;
        this.comentario.director = JSON.parse(localStorage.getItem("user"));
        var dateVar = new Date();
        var fecha = "";
        if (dateVar.getDate() < 10) {
          fecha += "0" + dateVar.getDate() + "-";
        } else {
          fecha += dateVar.getMonth() + "-";
        }
        var mes = dateVar.getMonth() + 1;
        if (mes < 10) {
          fecha += "0" + mes + "-" + dateVar.getFullYear();
        } else {
          fecha += mes + "-" + dateVar.getFullYear();
        }
        this.comentario.Fecha = fecha;
        this.comentarios.push(this.comentario);
        this.totalItems = this.comentarios.length;
        if (this.totalItems > this.pageSize) {
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
        this.fijarPagina(this.page);
        this.comentario = new Comentario();
        this.respuesta(data);
      },
      error: error => this.error(error) //this.estado = error
    });
  }
  comentario: Comentario = new Comentario();
  formularioComentario = new FormGroup({
    Comentario: new FormControl("", Validators.required)
  });
  ngOnInit() {
    this.id_user = JSON.parse(localStorage.getItem("user")).id;
    if(!this.desplegado){
      this.abierto = false;
      this.clase = "nuevo boton";
    }else{
      this.clase = "nuevo";
      this.abierto = true;
    }
    this.miId = JSON.parse(localStorage.getItem("user")).id;

    this.totalItems = this.comentarios.length;
    if (this.totalItems > this.pageSize) {
      this.showPagination = true;
    } else {
      this.showPagination = false;
    }
    this.previousPage = 1;
    this.fijarPagina(1);
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.fijarPagina(this.page - 1);
    }
  }
  fijarPagina(page: number): void {
    this.paginaComentarios = this.comentarios.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize
    );
  }

  eliminar(id) {
    this.comentarioService.removeComentario(id).subscribe({
      next: data => {
        console.log(id);
        for (var i = 0; i < this.comentarios.length; i++) {
          if (this.comentarios[i].id == id) {
            this.comentarios.splice(i, 1);
            break;
          }
        }
        this.totalItems = this.comentarios.length;
        this.fijarPagina(this.page);

        this.respuesta(data);
      }, //this.estado = data.status,
      error: error => this.error(error) //this.estado = error
    });
  }
desplegar(evento){
  this.abierto = !this.abierto;
  if(this.abierto){
    this.flecha = faAngleUp;
  }else{
    this.flecha = faAngleDown;
    }
}
  error(error) {
    alert("Error desconocido");
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      console.log(data);
      //alert("correcto");
    } else {
      alert("Error");
      console.log(data);
    }
    //this.propagar.emit(false);
  }
}
