import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, ValidationErrors } from "@angular/forms";
import { CentroService } from "../centro.service";
import { Centro } from "../modelos/centro.model";
import { Director } from "../modelos/director.model";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { Router, ActivatedRoute } from "@angular/router";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF
} from "@fortawesome/free-brands-svg-icons";
import { environment } from '../../environments/environment';
import { UsersService } from "../users.service";

@Component({
  selector: "app-nuevo-centro",
  templateUrl: "./nuevo-centro.component.html",
  styleUrls: ["./nuevo-centro.component.css"]
})
export class NuevoCentroComponent implements OnInit {


  directores: Director[];
  faArrowLeft = faArrowLeft;
  faCamera = faCamera;
  editar: boolean = false;
  faTwitter = faTwitter;
  faLinkedinIn = faLinkedinIn;
  faInstagram = faInstagram;
  faFacebookF = faFacebookF;
  masDirectores() {


    this.formulariCentro.addControl("NomApe" + this.directores.length, new FormControl("", Validators.required));
    this.formulariCentro.addControl("Nivel" + this.directores.length, new FormControl("", Validators.required));
    this.formulariCentro.addControl("username" + this.directores.length, new FormControl("", [Validators.required, Validators.email]));

    this.directores.push(new Director());

  }

  formulariCentro = new FormGroup({
    Nombre: new FormControl("", Validators.required),
    Direccion: new FormControl("", Validators.required),
    Telefono: new FormControl("", Validators.required),
    Email: new FormControl("", [Validators.required, Validators.email]),
    Comunidad: new FormControl("", Validators.required),
    Web: new FormControl("", Validators.required),
    Facebook: new FormControl(""),
    Instagram: new FormControl(""),
    Twitter: new FormControl(""),
    Linkedin: new FormControl(""),
    Infantil: new FormControl("false"),
    Primaria: new FormControl("false"),
    ESO: new FormControl("false"),
    Bachillerato: new FormControl("false"),
    Ciclos: new FormControl("false"),
    Universidad: new FormControl("false"),
    Fundacion: new FormControl("false"),
    Asociacion: new FormControl("false")

  });
  centroParametros = new Centro();
  urlServer = environment.url;
  constructor(protected userService: UsersService, protected centroService: CentroService, private router: Router,
    private route: ActivatedRoute) {}

  ngOnInit() {

    let admin = false;
    let roles = JSON.parse(localStorage.getItem("user")).roles;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] == "ROLE_ADMIN") {
        admin = true;
      }
    }

    if (admin) {
      this.formulariCentro = new FormGroup({
        Nombre: new FormControl("", Validators.required),
        Direccion: new FormControl(""),
        Telefono: new FormControl(""),
        Email: new FormControl(""),
        Comunidad: new FormControl("", Validators.required),
        Web: new FormControl(""),
        Facebook: new FormControl(""),
        Instagram: new FormControl(""),
        Twitter: new FormControl(""),
        Linkedin: new FormControl(""),
        Infantil: new FormControl("false"),
        Primaria: new FormControl("false"),
        ESO: new FormControl("false"),
        Bachillerato: new FormControl("false"),
        Ciclos: new FormControl("false"),
        Universidad: new FormControl("false"),
        Fundacion: new FormControl("false"),
        Asociacion: new FormControl("false")

      });
    } else {
      this.formulariCentro = new FormGroup({
        Nombre: new FormControl("", Validators.required),
        Direccion: new FormControl("", Validators.required),
        Telefono: new FormControl("", Validators.required),
        Email: new FormControl("", [Validators.required, Validators.email]),
        Comunidad: new FormControl("", Validators.required),
        Web: new FormControl("", Validators.required),
        Facebook: new FormControl(""),
        Instagram: new FormControl(""),
        Twitter: new FormControl(""),
        Linkedin: new FormControl(""),
        Infantil: new FormControl("false"),
        Primaria: new FormControl("false"),
        ESO: new FormControl("false"),
        Bachillerato: new FormControl("false"),
        Ciclos: new FormControl("false"),
        Universidad: new FormControl("false"),
        Fundacion: new FormControl("false"),
        Asociacion: new FormControl("false")
      });
    }

    this.route.paramMap.subscribe(params => {
      //alert(params.get("id"));
      if (params.get("id") != null) {
        this.editar = true;
        this.centroService.getCentro(params.get("id")).subscribe({
          next: data => {
            this.centroParametros = <Centro>data;
            this.directores = new Array();
            for (var i = 0; i < this.centroParametros.directores.length; i++) {
              // var dir = {"NomApe":data.directores[i].user.NomApe,"Nivel":data.directores[i].Nivel,"username":data.directores[i].user.username};
              var dir = new Director();
              dir.Nivel = this.centroParametros.directores[i].Nivel;
              dir.NomApe = this.centroParametros.directores[i].user.NomApe;
              dir.username = this.centroParametros.directores[i].user.username;
              console.log(dir);
              this.directores.push(dir);
              this.formulariCentro.addControl("NomApe" + i, new FormControl("", Validators.required));
              this.formulariCentro.addControl("Nivel" + i, new FormControl("", Validators.required));
              this.formulariCentro.addControl("username" + i, new FormControl("", [Validators.required, Validators.email]));
            }

          }, //this.estado = data.status,
          error: error => this.error(error) //this.estado = error

        })
      }
    });




    this.formulariCentro.addControl("NomApe0", new FormControl("", Validators.required));
    this.formulariCentro.addControl("Nivel0", new FormControl("", Validators.required));
    this.formulariCentro.addControl("username0", new FormControl("", [Validators.required, Validators.email]));

    this.directores = new Array();
    this.directores.push(new Director());
    this.centroParametros.Infantil = false;
    this.centroParametros.Primaria = false;
    this.centroParametros.ESO = false;
    this.centroParametros.Bachillerato = false;
    this.centroParametros.Ciclos = false;
    this.centroParametros.Universidad = false;
    this.centroParametros.Fundacion = false;
    this.centroParametros.Asociacion = false;
    this.centroParametros.Facebook = "";
    this.centroParametros.Instagram = "";
    this.centroParametros.Twitter = "";
    this.centroParametros.Linkedin = "";
  }

  onSubmit() {
    if (this.editar) {
      this.centroParametros.directores = this.directores;
      this.centroService.editCentro(this.centroParametros).subscribe({
        next: data => this.respuesta(data), //this.estado = data.status,
        error: error => this.error(error) //this.estado = error
      });
    } else {
      this.centroParametros.directores = this.directores;
      this.centroService.addCentro(this.centroParametros).subscribe({
        next: data => {
          console.log(data);
          if (this.imagen) {
            this.userService.avatarCentro(this.blobToFile(this.fileAvatar, "image.png"), data.success).subscribe({
              next: data => {
                console.log(data);
                this.respuesta(data)
              }, //this.estado = data.status,
              error: error => this.error(error) //this.estado = error
            });
          }
          this.respuesta(data);



        }, //this.estado = data.status,
        error: error => this.error(error) //this.estado = error
      });
    }
  }
  atras() {
    this.router.navigate(["/perfil"]);
  }
  error(error) {
    alert("Error desconocido");
    console.error(error);
  }
  respuesta(data) {
    if (data.status == "200") {
      console.log(data);
      this.router.navigate(["/perfil"]);
    } else {
      alert("Error");
      console.log(data);
    }
    //this.propagar.emit(false);
  }
  resizeImg = false;
  eligirImg() {
    this.resizeImg = true;
  }
  cerrar() {
    this.resizeImg = false;
  }
  activoConfirm = false;
  tituloConfirm = "Eliminar Centro";
  mensajeConfirm = "¿Seguro que desea eliminar el centro?, todo lo asociado a este centro será eliminado";
  eliminar() {
    this.activoConfirm = true;
  }

  procesaCerrarConfirmar(mensaje) {
    this.activoConfirm = false;
    if (mensaje) {
      this.confirmarEliminar();
    }
  }
  confirmarEliminar() {
    this.centroService.removeCentro(this.centroParametros.id).subscribe({
      next: data => this.router.navigate(["/perfil"]),
      error: error => this.error(error) //this.estado = error
    });
  }
  imageUrl;
  public blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  }
  fileAvatar: Blob;
  imagen = false;
  recogerImagen(mensaje) {
    this.imagen = true;
    this.imageUrl = mensaje;
    this.fileAvatar = this.dataURItoBlob(mensaje);
    console.log(this.fileAvatar);
    this.resizeImg = false;
    if (this.editar) {
      this.userService.avatarCentro(this.blobToFile(this.fileAvatar, "image.png"), this.centroParametros.id).subscribe({
        next: data => console.log(data), //this.estado = data.status,
        error: error => this.error(error) //this.estado = error
      });
    }
  }
  dataURItoBlob(dataURI) {
    let byteString;
    if (dataURI.split(',').length > 1) {
      console.log(dataURI.split(',')[1]);
      byteString = window.atob(dataURI.split(',')[1]);
    } else {
      byteString = window.atob(dataURI);
    }
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  eliminarDirector(director){
    this.directores.splice(this.directores.indexOf(director),1);
  }
}
